$(function() {

    // let _href;
    $('.submit_btn').click(function() {
        location.href = '/search?select=' + $('select[name=select]').val() +
            // '&newscate=' + $('select[name=newscate]').val() +
            // '&periodical1=' + $('select[name=periodical1]').val() +
            // '&periodical2=' + $('select[name=periodical2]').val() +
            '&title=' + $('input[name=title]').val() +
            '&columnname=' + $('input[name="columnname[]"]:checked').map(function() { return $(this).val(); }).get() +
            '&hashtag=' + $('input[name="hashtag[]"]:checked').map(function() { return $(this).val(); }).get();
    });

    const $select_sub = $('.select_sub');
    const $select_news = $('.select_news');
    const $select_journal1 = $('.select_journal1');
    const $select_journal2 = $('.select_journal2');
    const $select_default = $('.select_default');
    const $select = $('select[name=select]');
    searchInput_change($select.val());
    $select.change(function() {
        $select_sub.find('select').val('').change();
        searchInput_change($(this).val());
    });

    function searchInput_change(_val) {
        if (_val == 2) {
            $select_sub.hide();
            $select_news.show();
        } else if (_val == 3) {
            $select_sub.hide();
            $select_journal1.show();
        } else if (_val == 4) {
            $select_sub.hide();
            $select_journal2.show();
        } else {
            $select_sub.hide();
            $select_default.show();
        }
    }

});