import '../../css/home/index.scss';
import '../share/components/_searchFn';

$(function() {

    let $swiper;
    let swiper_el;
    let container;
    let pagination;
    let button_next;
    let button_prev;

    //swiper ------------------------------------------
    if ($(".kv_swiper").length > 0) {

        //kv_swiper
        swiper_el = '.kv_swiper';
        container = swiper_el + ' .swiper';
        pagination = swiper_el + ' .swiper-pagination';
        button_next = swiper_el + ' .swiper-button-next';
        button_prev = swiper_el + ' .swiper-button-prev';

        const $kv_swiper = new Swiper(container, {
            grabCursor: true,
            loop: true,
            loopedSlides: 1,
            speed: 600,
            autoplay: {
                delay: 3000,
            },
            pagination: {
                el: pagination,
                clickable: true,
            },
            navigation: {
                nextEl: button_next,
                prevEl: button_prev,
            },
            a11y: {
                enabled: true,
                firstSlideMessage: "第一則",
                lastSlideMessage: "最後一則",
                paginationBulletMessage: "到第 {{index}} 則",
            },
        });

        $kv_swiper.autoplay.stop();

        const kv_timeline = gsap.timeline({ paused: true, delay: .5 })
            .from(".kv .main_obj", { duration: 1, opacity: 0, ease: 'quart.out' })
            .from(".kv .track", { duration: 1.1, top: '70%', scale: 0.5, opacity: 0, ease: 'quart.out' }, '-=.6')
            .from(".kv .obj", { duration: 1, stagger: { each: 0.11 }, top: '50%', left: '50%', scale: 0, rotation: -170, opacity: 0, ease: 'back.out' }, '-=.6')
            .from(".kv .circle", { duration: 1, stagger: { each: 0.13 }, top: '50%', left: '50%', scale: 0, opacity: 0, ease: 'back.out' }, '-=2')
            .from(".kv .letter", { duration: 1, stagger: { each: 0.13 }, top: '50%', left: '50%', scale: 0, rotation: 300, opacity: 0, ease: 'quart.out' }, '-=3')
            .from(".kv .letter_txt", { duration: 1, stagger: { each: 0.15 }, opacity: 0, ease: 'power0.out', onComplete: $kv_swiper.autoplay.start }, '-=2.5')
        kv_timeline.play();

        let _href;
        $('.kv_swiper').find('.swiper-slide').click(function() {
            _href = $(this).data('href');
            if (_href) {
                window.open(_href, '_blank');
            }
        });

        $(document).on('keypress', '.kv_swiper .swiper-slide', function(e) {
            if (e.which == 13) {
                $(this).click();
            }
        });
    }

    if ($(".column_swiper").length > 0) {

        swiper_el = '.column_swiper';
        container = swiper_el + ' .swiper';
        pagination = swiper_el + ' .swiper-pagination';
        button_next = swiper_el + ' .swiper-button-next';
        button_prev = swiper_el + ' .swiper-button-prev';

        $swiper = new Swiper(container, {
            grabCursor: true,
            slidesPerView: 2.46,
            speed: 400,
            pagination: {
                el: pagination,
                clickable: true,
            },
            navigation: {
                nextEl: button_next,
                prevEl: button_prev,
            },
            a11y: {
                enabled: true,
                firstSlideMessage: "第一則",
                lastSlideMessage: "最後一則",
                paginationBulletMessage: "到第 {{index}} 則",
            },
            breakpoints: {
                320: {
                    slidesPerView: 1.1,
                },
                480: {
                    slidesPerView: 2.15,
                },
                950: {
                    slidesPerView: 2.46,
                }
            }
        });

    }

    // if ($(".news_swiper").length > 0) {

    //     swiper_el = '.news_swiper';
    //     container = swiper_el + ' .swiper';
    //     pagination = swiper_el + ' .swiper-pagination';
    //     button_next = swiper_el + ' .swiper-button-next';
    //     button_prev = swiper_el + ' .swiper-button-prev';

    //     $swiper = new Swiper(container, {
    //         grabCursor: true,
    //         slidesPerView: 3,
    //         speed: 400,
    //         freeMode: true,
    //         pagination: {
    //             el: pagination,
    //             clickable: true,
    //         },
    //         navigation: {
    //             nextEl: button_next,
    //             prevEl: button_prev,
    //         },
    //         // controller: {
    //         //     inverse: true,
    //         // },
    //         a11y: {
    //             enabled: true,
    //             firstSlideMessage: "第一則",
    //             lastSlideMessage: "最後一則",
    //             paginationBulletMessage: "到第 {{index}} 則",
    //         },
    //         breakpoints: {
    //             320: {
    //                 slidesPerView: 1.1,
    //             },
    //             480: {
    //                 slidesPerView: 2.1,
    //             },
    //             950: {
    //                 slidesPerView: 3,
    //             }
    //         }
    //     });

    // }


    gsap.registerPlugin(MotionPathPlugin);


    gsap.set(".news_sec .maintitle_en", { y: 100, opacity: 0 })
    gsap.set(".news_sec .maintitle_tw", { y: 100, opacity: 0 })
    gsap.set(".news_item", { x: 500, opacity: 0 })
    gsap.set(".news_sec .more_row", { y: 20, opacity: 0 })
    gsap.timeline({ scrollTrigger: { trigger: ".news_sec", start: "top center", } })
        .to(".news_sec .maintitle_en", { duration: .6, y: 0, opacity: 1, ease: 'circ.out' })
        .to(".news_sec .maintitle_tw", { duration: .6, y: 0, opacity: 1, ease: 'circ.out' }, '-=.3')
        .to(".news_item", { x: 0, opacity: 1, stagger: { duration: .6, each: 0.13 } }, '-=.3')
        .to(".news_sec .more_row", { duration: .5, y: 0, opacity: 1, ease: 'circ.out' }, '-=.1')

    var winW = $(window).width();
    gsap.set(".column_sec .maintitle_en", { y: 100, opacity: 0 })
    gsap.set(".column_sec .maintitle_tw", { y: 100, opacity: 0 })
    gsap.set(".column_item", { x: -winW, opacity: 0 })
    gsap.set(".column_sec .swiper-pagination", { opacity: 0 })
    gsap.set(".column_sec .more_row", { y: 20, opacity: 0 })
    gsap.timeline({ scrollTrigger: { trigger: ".column_sec", start: "top center", } })
        .to(".column_sec .maintitle_en", { duration: .6, y: 0, opacity: 1, ease: 'circ.out' })
        .to(".column_sec .maintitle_tw", { duration: .6, y: 0, opacity: 1, ease: 'circ.out' }, '-=.3')
        .to(".column_item", { duration: 1.2, x: 0, opacity: 1, stagger: -.13, ease: "power4.out" }, '-=.3')
        .to(".column_sec .swiper-pagination", { duration: .5, y: 0, opacity: 1, ease: 'circ.out' }, '-=.5')
        .to(".column_sec .more_row", { duration: .5, y: 0, opacity: 1, ease: 'circ.out' }, '-=.1')


    gsap.set(".arti_li", { y: -320, scale: 0 })
    gsap.set(".arti_sec .more_row", { y: 20, opacity: 0 })
    gsap.timeline({ scrollTrigger: { trigger: ".arti_sec", start: "top center", } })
        .to(".arti_li", { duration: .9, y: 0, scale: 1, stagger: .13, ease: "back.out" })
        .to(".arti_sec .more_row", { duration: .5, y: 0, opacity: 1, ease: 'circ.out' }, '-=.1')


    gsap.set(".search_sec .container", { opacity: 0 })
    gsap.timeline({ scrollTrigger: { trigger: ".search_sec", start: "top 10%", } })
        .to(".search_sec .container", { duration: .8, opacity: 1, ease: "power0.out" })


    gsap.set(".gallery_1 .maintitle_en", { y: 100, opacity: 0 })
    gsap.set(".gallery_1 .maintitle_tw", { y: 100, opacity: 0 })
    gsap.set(".gallery_1 .gallery_spinner", { rotation: -180, opacity: 0 })
    gsap.set(".gallery_1 .more_row", { y: 20, opacity: 0 })
    gsap.set(".gallery_1 .gallery_prev,.gallery_1 .gallery_next", { opacity: 0 })
    gsap.timeline({ scrollTrigger: { trigger: ".gallery_1", start: "top center", } })
        .to(".gallery_1 .maintitle_en", { duration: .6, y: 0, opacity: 1, ease: 'circ.out' })
        .to(".gallery_1 .maintitle_tw", { duration: .6, y: 0, opacity: 1, ease: 'circ.out' }, '-=.3')
        .to(".gallery_1 .gallery_spinner", { rotation: 0, opacity: 1, duration: 1, ease: 'circ.out' }, '-=.3')
        .to(".gallery_1 .gallery_prev,.gallery_1 .gallery_next", { duration: .5, opacity: 1, ease: 'power0.out' }, '-=.3')
        .to(".gallery_1 .more_row", { duration: .5, y: 0, opacity: 1, ease: 'circ.out' }, '-=.2')


    gsap.set(".gallery_2 .maintitle_en", { y: 100, opacity: 0 })
    gsap.set(".gallery_2 .maintitle_tw", { y: 100, opacity: 0 })
    gsap.set(".gallery_2 .gallery_spinner", { rotation: 180, opacity: 0 })
    gsap.set(".gallery_2 .more_row", { y: 20, opacity: 0 })
    gsap.set(".gallery_2 .gallery_prev,.gallery_2 .gallery_next", { opacity: 0 })
    gsap.timeline({ scrollTrigger: { trigger: ".gallery_2", start: "top center", } })
        .to(".gallery_2 .maintitle_en", { duration: .6, y: 0, opacity: 1, ease: 'circ.out' })
        .to(".gallery_2 .maintitle_tw", { duration: .6, y: 0, opacity: 1, ease: 'circ.out' }, '-=.3')
        .to(".gallery_2 .gallery_spinner", { rotation: 15, opacity: 1, duration: 1, ease: 'circ.out' }, '-=.3')
        .to(".gallery_2 .gallery_prev,.gallery_2 .gallery_next", { duration: .5, opacity: 1, ease: 'power0.out' }, '-=.3')
        .to(".gallery_2 .more_row", { duration: .5, y: 0, opacity: 1, ease: 'circ.out' }, '-=.2')

    gsap.timeline({
            scrollTrigger: {
                trigger: ".gallery_1",
                scrub: true,
                start: "top bottom",
                end: "bottom bottom"
            },
            defaults: {
                duration: 1
            }
        })
        .to(".gallery_bg", { scale: 2.5, ease: 'none' })


    gsap.set(".hashtags_sec .maintitle_en", { y: 100, opacity: 0 })
    gsap.set(".hashtags_sec .maintitle_tw", { y: 100, opacity: 0 })
    gsap.set(".hashtags_li", { scale: 0, y: -100, opacity: 0 })
    gsap.timeline({ scrollTrigger: { trigger: ".hashtags_sec", start: "top 70%" } })
        .to(".hashtags_sec .maintitle_en", { duration: .6, y: 0, opacity: 1, ease: 'circ.out' })
        .to(".hashtags_sec .maintitle_tw", { duration: .6, y: 0, opacity: 1, ease: 'circ.out' }, '-=.3')
        .to(".hashtags_li", { scale: 1, y: 0, opacity: 1, duration: .8, ease: 'back.out', stagger: { each: .05, from: "edges" } }, '-=.3')



    $('.search_tag').each(function(i, o) {
        gsap.to($(o), {
            delay: 6.66 * i,
            duration: 40,
            repeat: -1,
            ease: Power0.easeOut,
            motionPath: {
                path: "#search_path",
                align: "#search_path",
                // autoRotate: true,
                alignOrigin: [0.5, 0.5]
            }
        });
    });




    $(".gallery_container").each(function(i, o) {
        var $this = $(o);
        var wheel = $this.find(".gallery_spinner");
        var rotationSnap = 360 / 24;
        var endRotation = 0;
        var direction = 0;
        var minRotation = -30;
        var maxRotation = 30;
        var currentRotation;

        var draggable = new Draggable(wheel, {
            type: 'rotation',
            inertia: true,
            edgeResistance: 0.5,
            snap: snap,
            bounds: {
                minRotation: minRotation,
                maxRotation: maxRotation,
            },
            onPress: function() {
                direction = 0;
            }
        });

        $this.find(".gallery_prev").click(prev);
        $this.find(".gallery_next").click(next);

        function next() {
            if (direction !== -1) {
                direction = -1;
                currentRotation = parseInt($this.find(".gallery_spinner")[0]._gsap.rotation);
                endRotation = snap(currentRotation - rotationSnap);
            } else {
                endRotation -= rotationSnap;
            }

            if (endRotation <= minRotation) endRotation = minRotation;
            animateWheel(endRotation);
        }

        function prev() {

            if (direction !== 1) {
                direction = 1;
                currentRotation = parseInt($this.find(".gallery_spinner")[0]._gsap.rotation);
                endRotation = snap(currentRotation + rotationSnap);
            } else {
                endRotation += rotationSnap;
            }

            if (endRotation >= maxRotation) endRotation = maxRotation;
            animateWheel(endRotation);
        }

        function animateWheel(rotation) {
            TweenMax.to(wheel, 0.5, { rotation: rotation });
        }

        function snap(endValue) {
            return Math.round(endValue / rotationSnap) * rotationSnap;
        }
    });

});